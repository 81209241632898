




































import { computed, defineComponent } from '@vue/composition-api';

import { useMessages } from '../../Messages';
import BaseLabel from '../atoms/BaseLabel.vue';
import CourseBackground from './CourseBackground.vue';
import StatusIcon from './StatusIcon.vue';

type Props = {
  name: string;
  courseName?: string;
  examTimes?: number;
  requiredTime?: number;
  existsWorkbook: boolean;
  learningStatus?: string;
  courseColor?: string;
  courseImage?: string;
};

export default defineComponent({
  name: 'BaseContentHeader',
  components: { CourseBackground, BaseLabel, StatusIcon },
  inheritAttrs: false,
  props: {
    name: { type: String, required: true },
    courseName: { type: String, default: undefined },
    examTimes: { type: Number, default: undefined },
    requiredTime: { type: Number, default: undefined },
    existsWorkbook: { type: Boolean, default: false },
    learningStatus: { type: String, default: undefined },
    courseColor: { type: String, default: undefined },
    courseImage: { type: String, default: undefined },
    courseFontColorOnImage: { type: String, default: undefined },
    rounded: { type: [String, Boolean], default: 'top' },
    height: { type: Number, default: undefined },
    minHeight: { type: Number, default: 400 },
  },
  setup(props: Props) {
    const msgs = useMessages({ prefix: 'base.molecules.contentHeader' });
    const labelExamTimes = computed(
      () => msgs.of('examTimes', { times: props.examTimes || 0 }).value
    );
    const labelRequiredTime = computed(
      () => msgs.of('requiredTime', { minutes: props.requiredTime || 0 }).value
    );
    return {
      labelExamTimes,
      labelRequiredTime,
      labelExercises: msgs.of('haveExercises'),
    };
  },
});
