import { computed, ref } from '@vue/composition-api';

import { ProblemUtilsNavigator, ProblemUtilsNavigatorValue } from '@/base/app/utils/ProblemUtils';

export type PropsProblemNavigator = {
  problemNavigator?: ProblemUtilsNavigator;
  itemKey: 'passed' | 'choose';
};

export function useProblemNavigator(
  props: PropsProblemNavigator,
  emit: (name: string, arg: ProblemUtilsNavigatorValue) => void
) {
  const menu = ref(false);

  const list = computed(() => props.problemNavigator?.items ?? []);
  const count = computed(() => list.value.filter((item) => item[props.itemKey]).length);
  const total = computed(() => list.value.filter((item) => item.value !== 'results').length);
  const flags = computed(() => list.value.filter((item) => item.marked).length);

  function change(v: ProblemUtilsNavigatorValue) {
    emit('change', v);
  }

  return {
    menu,
    list,
    count,
    total,
    flags,
    change,
  };
}
