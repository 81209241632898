





















import { computed, defineComponent } from '@vue/composition-api';

import { useCourseColor } from '../../utils/ColorUtils';

const CLASS_PREFIX = 'course-background';

type Props = {
  courseColor?: string;
  courseImage?: string;
  courseFontColorOnImage?: string;
  contentClass?: string;
  rounded: boolean | string;
  shadow: boolean | 'auto';
};

export default defineComponent({
  name: 'BaseCourseBackground',
  inheritAttrs: false,
  props: {
    courseColor: { type: String, default: undefined },
    courseImage: { type: String, default: undefined },
    courseFontColorOnImage: { type: String, default: undefined },
    contentClass: { type: String, default: 'pa-0' },
    rounded: { type: [Boolean, String], default: false },
    shadow: { type: [Boolean, String], default: 'auto' },
    height: { type: [Number, String], default: 'auto' },
    minHeight: { type: [Number, String], default: undefined },
  },
  setup(props: Props) {
    const { courseColorClass } = useCourseColor(props);
    const sheetClass = computed(() => {
      const arr = [courseColorClass.value];
      if (props.rounded === true) arr.push(`${CLASS_PREFIX}-rounded--all`);
      else if (props.rounded !== false) arr.push(`${CLASS_PREFIX}-rounded--${props.rounded}`);
      return arr.join(' ');
    });
    const imgClass = computed(() => {
      if (!props.rounded) return undefined;
      if (props.rounded === true) return `${CLASS_PREFIX}-image-rounded--all`;
      return `${CLASS_PREFIX}-image-rounded--${props.rounded}`;
    });
    const imgContentClass = computed(() => {
      const arr: string[] = [];
      if (props.contentClass) arr.push(props.contentClass);
      if (props.shadow) {
        if (props.shadow === 'auto' && props.courseImage) arr.push('text-shadow');
        if (props.shadow === true) arr.push('text-shadow');
      }
      return arr.join(' ');
    });
    return { sheetClass, imgClass, imgContentClass };
  },
});
