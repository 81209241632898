import { computed } from '@vue/composition-api';

import { Optional } from '@/base/types';

export type WindowControlItem = {
  value: string;
  label: string;
  clearable?: boolean;
};

export type WindowControlValue = Optional<string>;

export type WindowControlClearValue = string;

export type PropsWindowControl = {
  value?: string;
  items: WindowControlItem[];
  fixed: boolean;
  side: boolean;
  sideWidth: number;
  paddingWidth: number;
  height?: number;
  top?: number;
  zIndex?: number;
};

export function useWindowControl(
  props: PropsWindowControl,
  emit: (name: string, value: WindowControlValue | WindowControlClearValue) => void
) {
  const styles = computed(() => ({
    position: props.fixed ? 'fixed' : undefined,
    height: props.height !== undefined ? `${props.height}px` : undefined,
    width: `calc(100% - ${props.side ? props.sideWidth : 0}px - ${props.paddingWidth}px)`,
    top: props.top !== undefined ? `${props.top}px` : undefined,
    'z-index': props.zIndex,
  }));

  function clear(item: WindowControlItem) {
    emit('clear', item.value);
  }

  function change(v?: string) {
    emit('change', v);
  }

  function next() {
    let index = props.items.findIndex((item) => item.value === props.value);
    if (index === -1 || props.items.length - 1 <= index) index = 0;
    else index += 1;
    emit('change', props.items[index].value);
  }

  return { styles, clear, change, next };
}
