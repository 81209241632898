import { computed, onMounted, ref, watch } from '@vue/composition-api';

import {
  ProblemUtilsNavigator,
  ProblemUtilsNavigatorValue,
  ProblemUtilsProblem,
} from '@/base/app/utils/ProblemUtils';

import { useMessages } from '../../Messages';
import { delayScroll } from '../../utils/DomUtils';
import {
  BaseMarkdownClickAnchorPayload,
  BaseMarkdownMarkSelection,
} from '../atoms/BaseMarkdownComposable';
import {
  ProblemChoiceMultipleClickAnchorPayload,
  ProblemChoiceMultipleMarkSelection,
  ProblemChoiceMultipleValue,
} from './ProblemChoiceMultipleComposable';
import {
  ProblemChoiceSingleClickAnchorPayload,
  ProblemChoiceSingleMarkSelection,
  ProblemChoiceSingleValue,
} from './ProblemChoiceSingleComposable';

export type ProblemViewChoiceValue = ProblemChoiceSingleValue | ProblemChoiceMultipleValue;

export type ProblemViewClickAnchorPayload =
  | BaseMarkdownClickAnchorPayload
  | ProblemChoiceSingleClickAnchorPayload
  | ProblemChoiceMultipleClickAnchorPayload;

export type ProblemViewMarkSelection =
  | BaseMarkdownMarkSelection
  | ProblemChoiceSingleMarkSelection
  | ProblemChoiceMultipleMarkSelection;

export type PropsProblemView = {
  problem: ProblemUtilsProblem;
  problemNavigator?: ProblemUtilsNavigator;
  displayMode: 'score' | 'toggle' | 'show' | 'hide';
  offsetX: number;
  offsetY: number;
  showProminent?: boolean;
  shrinkLimit?: number;
};

export function useProblemView(
  props: PropsProblemView,
  emit: (
    name: string,
    arg?:
      | ProblemViewChoiceValue
      | ProblemUtilsNavigatorValue
      | ProblemViewClickAnchorPayload
      | ProblemViewMarkSelection
  ) => void
) {
  const msgs = useMessages({ prefix: 'base.molecules.problemView' });

  const scored = ref(false);
  const displayScore = ref(false);
  const displayClear = computed(
    () => props.displayMode === 'score' && (props.problem.choiceIndexes.length > 0 || scored.value)
  );
  const displayNext = computed(() => {
    if (!props.problemNavigator) return undefined;
    const item =
      props.displayMode === 'score'
        ? props.problemNavigator.nextForce
        : props.problemNavigator.next;
    const { value, disabled } = item;
    const key = value === 'results' ? 'results' : 'next';
    return { text: msgs.of(key).value, value, disabled };
  });

  function init() {
    const { scoredChoiceNos, hasCommentaryMarker } = props.problem;
    displayScore.value = false;
    scored.value = false;
    if (
      props.displayMode === 'show' ||
      props.displayMode === 'toggle' ||
      hasCommentaryMarker ||
      (scoredChoiceNos && scoredChoiceNos.length > 0)
    ) {
      displayScore.value = true;
    }
    scored.value = props.displayMode === 'score' && !!scoredChoiceNos && scoredChoiceNos.length > 0;
  }
  onMounted(init);
  watch(
    () => props.problem.scoredChoiceNos?.length,
    (newVal) => {
      if (newVal) return;
      if (!displayScore.value && !scored.value) return;
      init();
    }
  );

  function toggle() {
    displayScore.value = !displayScore.value;
  }

  function clear(e: Event) {
    emit('clear');
    scored.value = false;
    if (props.displayMode === 'score' && !props.problem.hasCommentaryMarker) {
      displayScore.value = false;
    }
    const btn = e.target as Element;
    delayScroll('top', { scrollArea: btn.closest('.overflow-y-auto') ?? undefined });
  }

  function check(e: Event) {
    emit('check');
    scored.value = true;
    displayScore.value = true;
    let btn: Element | null = e.target as Element;
    if (btn.tagName !== 'BUTTON') btn = btn.closest('button');
    if (!btn) return;
    delayScroll(btn, { scrollArea: btn.closest('.overflow-y-auto') ?? undefined });
  }

  function choice(v: ProblemChoiceSingleValue | ProblemChoiceMultipleValue) {
    emit('choice', v);
  }

  function next(e: Event, value: ProblemUtilsNavigatorValue) {
    emit('change', value);
    const btn = e.target as Element;
    if (!btn) return;
    delayScroll('top', { scrollArea: btn.closest('.overflow-y-auto') ?? undefined });
  }

  function complete() {
    emit('complete');
  }

  function clickAnchor(
    payload:
      | BaseMarkdownClickAnchorPayload
      | ProblemChoiceSingleClickAnchorPayload
      | ProblemChoiceMultipleClickAnchorPayload
  ) {
    emit('click-anchor', payload);
  }

  function mark(
    selection:
      | BaseMarkdownMarkSelection
      | ProblemChoiceSingleMarkSelection
      | ProblemChoiceMultipleMarkSelection
  ) {
    emit('mark', selection);
  }

  const labelNo = computed(() => {
    if (!props.problemNavigator) return undefined;
    const now = props.problemNavigator.items.find((item) => item.value === props.problem.index);
    return now?.text;
  });
  const scoreProps = computed(() => {
    if (props.displayMode === 'hide' || !displayScore.value) return undefined;
    const { scoredChoiceNos, answerNos, hasCommentaryMarker } = props.problem;
    if (scoredChoiceNos === undefined || answerNos === undefined) return undefined;
    if (
      props.displayMode === 'show' ||
      props.displayMode === 'toggle' ||
      (props.displayMode === 'score' && hasCommentaryMarker && !scored.value)
    )
      return { answerNos: answerNos.join() };
    const correct = scoredChoiceNos.join() === answerNos.join() ? 'correct' : 'incorrect';
    const textColor = correct === 'incorrect' ? 'error--text' : undefined;
    return {
      answerNos: answerNos.join(),
      scoredNos: scoredChoiceNos.length === 0 ? msgs.of('nothing').value : scoredChoiceNos.join(),
      correct: msgs.of(correct).value,
      textClass: textColor,
      labelClass: { 'text-caption': true },
    };
  });

  return {
    displayScore,
    displayClear,
    displayNext,
    labelNo,
    scoreProps,
    labelCheck: msgs.of('checkAnswer'),
    labelClear: msgs.of('clearAnswer'),
    labelChoices: msgs.of('choices'),
    labelAnswer: msgs.of('answer'),
    labelCommentary: msgs.of('commentary'),
    labelDetails: msgs.of('details'),
    toggle,
    clear,
    check,
    choice,
    next,
    complete,
    clickAnchor,
    mark,
  };
}
