






import { computed, defineComponent } from '@vue/composition-api';

type Props = {
  value: boolean;
};

export default defineComponent({
  name: 'BaseButtonCheckbox',
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Boolean, default: false },
    label: { type: String, default: undefined },
  },
  emits: ['change'],
  setup(props: Props) {
    const icon = computed(() =>
      props.value ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline'
    );
    return { icon };
  },
});
