



















































































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { ProblemUtilsNavigator, ProblemUtilsProblem } from '@/base/app/utils/ProblemUtils';

import BaseButton from '../atoms/BaseButton.vue';
import BaseButtonIcon from '../atoms/BaseButtonIcon.vue';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import ProblemChoiceMultiple from './ProblemChoiceMultiple.vue';
import ProblemChoiceSingle from './ProblemChoiceSingle.vue';
import { PropsProblemView, useProblemView } from './ProblemViewComposable';

type Props = PropsProblemView;

export default defineComponent({
  name: 'BaseProblemView',
  components: {
    BaseButton,
    BaseButtonIcon,
    BaseMarkdown,
    ProblemChoiceMultiple,
    ProblemChoiceSingle,
  },
  inheritAttrs: false,
  props: {
    problem: { type: Object as PropType<ProblemUtilsProblem>, required: true },
    problemNavigator: { type: Object as PropType<ProblemUtilsNavigator>, default: undefined },
    baseMarkerClassName: { type: String, default: undefined },
    displayMode: { type: String as PropType<'score' | 'toggle' | 'show' | 'hide'>, required: true },
    iconComplete: { type: String, default: 'mdi-check-circle-outline' },
    labelComplete: { type: String, default: undefined },
    enableMarking: { type: Boolean, default: false },
    offsetX: { type: Number, default: 8 },
    offsetY: { type: Number, default: 104 },
    showProminent: { type: Boolean, default: false },
    shrinkLimit: { type: Number, default: 16 },
    disabled: { type: Boolean, default: false },
  },
  emits: ['clear', 'change', 'check', 'choice', 'complete', 'click-anchor', 'mark'],
  setup(props: Props, { emit }: SetupContext) {
    return useProblemView(props, emit);
  },
});
