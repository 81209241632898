

































import { computed, defineComponent, PropType } from '@vue/composition-api';

import BaseLink from '@/base/app/components/atoms/BaseLink.vue';

import { useMessages } from '../../Messages';
import BaseButton from '../atoms/BaseButton.vue';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';

type Props = {
  name: string;
  courseName?: string;
  groupId?: string;
  courseId?: string;
  version?: number;
  existsWorkbook: boolean;
  learningStatus?: string;
  controlAttrs?: Record<string, string>;
  sheetClass?: string;
  hideControl: boolean;
};

export default defineComponent({
  name: 'BaseContentFooter',
  components: { BaseMarkdown, BaseButton, BaseLink },
  props: {
    name: { type: String, required: true },
    courseId: { type: String, default: undefined },
    courseName: { type: String, default: undefined },
    version: { type: Number, default: undefined },
    groupId: { type: String, default: undefined },
    existsWorkbook: { type: Boolean, default: undefined },
    learningStatus: { type: String, default: undefined },
    controlAttrs: { type: Object as PropType<Record<string, string>>, default: undefined },
    sheetClass: { type: String, default: undefined },
    hideControl: { type: Boolean, default: false },
  },
  emits: ['to-workbook', 'complete'],
  setup(props: Props) {
    const msgs = useMessages({ prefix: 'base.molecules.contentFooter' });

    const isCompleted = computed(() => props.learningStatus === 'completed');
    const isLink = computed(() => props.courseId && props.groupId);
    const to = computed(() =>
      isLink.value
        ? { name: 'groupCourse', params: { id: props.groupId, courseId: props.courseId } }
        : ''
    );
    const descriptionComplete = computed(
      () => msgs.of('completeDescription', { content: props.name }).value
    );

    return {
      isCompleted,
      isLink,
      to,
      labelToWorkbook: msgs.of('toWorkbook'),
      labelComplete: msgs.of('complete'),
      descriptionToWorkbook: msgs.of('toWorkbookDescription'),
      descriptionComplete,
    };
  },
});
