


























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BaseBaseLabel',
  inheritAttrs: false,
  props: {
    truncate: { type: Boolean, default: true },
    prependIcon: { type: String, default: undefined },
    appendIcon: { type: String, default: undefined },
    iconColor: { type: String, default: undefined },
    prependIconClass: { type: Object, default: undefined },
    appendIconClass: { type: Object, default: undefined },
  },
});
