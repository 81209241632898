


























































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { ProblemUtilsNavigator, ProblemUtilsNavigatorValue } from '@/base/app/utils/ProblemUtils';

import { PropsProblemNavigator, useProblemNavigator } from './ProblemNavigatorComposable';

type Props = PropsProblemNavigator;

export default defineComponent({
  name: 'BaseProblemNavigator',
  props: {
    value: { type: [Number, String] as PropType<ProblemUtilsNavigatorValue>, default: undefined },
    problemNavigator: { type: Object as PropType<ProblemUtilsNavigator>, default: undefined },
    itemKey: { type: String as PropType<'passed' | 'choose'>, default: 'passed' },
    iconPositive: { type: String, default: 'mdi-checkbox-marked-circle-outline' },
    iconNegative: { type: String, default: undefined },
    iconMarked: { type: String, default: 'mdi-flag-variant' },
    buttonFontClass: { type: String, default: 'text-h3' },
    buttonHeight: { type: String, default: '3rem' },
    buttonClass: { type: String, default: 'mx-3' },
  },
  emits: ['change'],
  setup(props: Props, { emit }: SetupContext) {
    return useProblemNavigator(props, emit);
  },
});
