


































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { PropsWindowControl, useWindowControl, WindowControlItem } from './WindowControlComposable';

type Props = PropsWindowControl;

export default defineComponent({
  name: 'BaseWindowControl',
  components: {},
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: String, default: undefined },
    items: { type: Array as PropType<WindowControlItem[]>, default: () => [] },
    fixed: { type: Boolean, default: false },
    side: { type: Boolean, default: false },
    sideWidth: { type: Number, default: 280 },
    paddingWidth: { type: Number, default: 0 },
    height: { type: Number, default: 40 },
    top: { type: Number, default: undefined },
    zIndex: { type: Number, default: undefined },
  },
  emits: ['change', 'clear'],
  setup(props: Props, { emit }: SetupContext) {
    return useWindowControl(props, emit);
  },
});
