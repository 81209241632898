













import { computed, defineComponent, PropType } from '@vue/composition-api';

import { LearningStatus, UserExamStatus } from '@/base/domains';

import { useMessages } from '../../Messages';
import BaseLabel from '../atoms/BaseLabel.vue';

type Props = { status?: LearningStatus | UserExamStatus };

export default defineComponent({
  name: 'BaseStatusIcon',
  components: { BaseLabel },
  inheritAttrs: false,
  props: {
    status: { type: String as PropType<LearningStatus | UserExamStatus>, default: undefined },
    showLabel: { type: Boolean, default: false },
  },
  setup(props: Props) {
    const msgs = useMessages({ prefix: 'base.molecules.statusIcon' });
    const icon = computed(() => {
      if (!props.status) return undefined;
      switch (props.status) {
        case 'completed':
        case 'finished':
          return 'mdi-check-circle';
        case 'in_progress':
          return 'mdi-progress-check';
        default:
      }
      return undefined;
    });
    const label = computed(() => {
      if (!props.status) return undefined;
      switch (props.status) {
        case 'completed':
        case 'finished':
          return msgs.of('complete').value;
        case 'in_progress':
          return msgs.of('in_progress').value;
        default:
      }
      return undefined;
    });
    return { icon, label };
  },
});
